function handleVariables(item,channelType) {
    let wearthVariable = [
        {
            name: "通用",
            type:'currency',
            children: [
                {
                    name: "今天",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd')}",
                },
                {
                    name: "昨天",
                    value: "${#temporals.format(date.today().minus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "前天",
                    value: "${#temporals.format(date.today().minus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "明天",
                    value: "${#temporals.format(date.today().plus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "后天",
                    value: "${#temporals.format(date.today().plus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "本周",
                    value: "|${#temporals.format(date.this_week().monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上周",
                    value: "|${#temporals.format(date.this_week().minus(1).monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().minus(1).sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "本月",
                    value: "|${#temporals.format(date.this_month().firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上月",
                    value: "|${#temporals.format(date.this_month().minus(1).firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().minus(1).lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前7天",
                    value: "|${#temporals.format(date.today().minus(7).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前30天",
                    value: "|${#temporals.format(date.today().minus(30).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "自定义日期范围",
                },
                {
                    name: "当前时间",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd HH:mm:ss')}",
                },
            ],
        },
        {
            name: "天气",
            type:'weather',
            children: [
                {
                    name: "当前天气情况,如晴",
                    value: "${weather.weather_info(cityCode,'today').weather}",
                },
                {
                    name: "当前温度",
                    value: "${weather.weather_info(cityCode,'today').temperature}",
                },
                {
                    name: "当前湿度",
                    value: "${weather.weather_info(cityCode,'today').humidity}",
                },
                {
                    name: "当前风向",
                    value: "${weather.weather_info(cityCode,'today').winddirection}",
                },
                {
                    name: "当前风力",
                    value: "${weather.weather_info(cityCode,'today').windpower}",
                },
                {
                    name: "明天天气情况,如晴",
                    value: "${weather.weather_info(cityCode,'tomorrow').weather}",
                },
                {
                    name: "明天温度",
                    value: "${weather.weather_info(cityCode,'tomorrow').temperature}",
                },
                {
                    name: "明天风向",
                    value: "${weather.weather_info(cityCode,'tomorrow').winddirection}",
                },
                {
                    name: "明天风力",
                    value: "${weather.weather_info(cityCode,'tomorrow').windpower}",
                },
                {
                    name: "后天天气情况,如晴",
                    value: "${weather.weather_info(cityCode,'the_day_after_tomorrow').weather}",
                },
                {
                    name: "后天温度",
                    value: "${weather.weather_info(cityCode,'the_day_after_tomorrow').temperature}",
                },
                {
                    name: "后天风向",
                    value: "${weather.weather_info(cityCode,'the_day_after_tomorrow').winddirection}",
                },
                {
                    name: "后天风力",
                    value: "${weather.weather_info(cityCode,'the_day_after_tomorrow').windpower}",
                },
            ],
        },
    ];
    let datacardVariable = [
        {
            name: "通用",
            type:'currency',
            children: [
                {
                    name: "今天",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd')}",
                },
                {
                    name: "昨天",
                    value: "${#temporals.format(date.today().minus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "前天",
                    value: "${#temporals.format(date.today().minus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "明天",
                    value: "${#temporals.format(date.today().plus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "后天",
                    value: "${#temporals.format(date.today().plus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "本周",
                    value: "|${#temporals.format(date.this_week().monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上周",
                    value: "|${#temporals.format(date.this_week().minus(1).monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().minus(1).sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "本月",
                    value: "|${#temporals.format(date.this_month().firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上月",
                    value: "|${#temporals.format(date.this_month().minus(1).firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().minus(1).lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前7天",
                    value: "|${#temporals.format(date.today().minus(7).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前30天",
                    value: "|${#temporals.format(date.today().minus(30).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "自定义日期范围",
                },
                {
                    name: "当前时间",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd HH:mm:ss')}",
                },
            ],
        },
        {
            name: "机器人数据",
            type:'datecard',
            children: [
                {
                    name: "总接待人数",
                    value: "reception_num",
                    type:'datecard'
                },
                {
                    name: "总接待会话数",
                    value: "session_num",
                    type:'datecard'
                },
                {
                    name: "咨询消息数",
                    value: "message_num",
                    type:'datecard'
                },
                {
                    name: "识别率",
                    value: "identify_rate",
                    type:'datecard'
                },
                {
                    name: "机器人平均首次响应时长",
                    value: "average_first_resp_time",
                    type:'datecard'
                },
                {
                    name: "机器人平均响应时长",
                    value: "average_resp_time",
                    type:'datecard'
                },
                {
                    name: "意图咨询TOP10",
                    value: "intent_top_n",
                    type:'datecard'
                },
                {
                    name: "未识别问题TOP10",
                    value: "unrecognized_top_n",
                    type:'datecard'
                },
            ],
        },
    ];
    let currency = [
        {
            name: "通用",
            children: [
                {
                    name: "今天",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd')}",
                },
                {
                    name: "昨天",
                    value: "${#temporals.format(date.today().minus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "前天",
                    value: "${#temporals.format(date.today().minus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "明天",
                    value: "${#temporals.format(date.today().plus(1).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "后天",
                    value: "${#temporals.format(date.today().plus(2).date(),'yyyy-MM-dd')}",
                },
                {
                    name: "本周",
                    value: "|${#temporals.format(date.this_week().monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上周",
                    value: "|${#temporals.format(date.this_week().minus(1).monday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_week().minus(1).sunday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "本月",
                    value: "|${#temporals.format(date.this_month().firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "上月",
                    value: "|${#temporals.format(date.this_month().minus(1).firstday().date(),'yyyy-MM-dd')}~${#temporals.format(date.this_month().minus(1).lastday().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前7天",
                    value: "|${#temporals.format(date.today().minus(7).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "前30天",
                    value: "|${#temporals.format(date.today().minus(30).date(),'yyyy-MM-dd')}~${#temporals.format(date.today().date(),'yyyy-MM-dd')}|",
                },
                {
                    name: "自定义日期范围",
                },
                {
                    name: "当前时间",
                    value: "${#temporals.format(date.today().date(),'yyyy-MM-dd HH:mm:ss')}",
                },
            ],
        },
    ]
    let variableList =[]
    if (channelType==='WE_WORK'||channelType==='DING_TALK'){
       /* let obj = {
            name: "企业员工ID",
            value: "${member.userId()}",
        }*/
        let obj = {
            name: "员工工号",
            value: "${member.jobNumber()}",
        }
        wearthVariable[0].children.push(obj)
        datacardVariable[0].children.push(obj)
        currency[0].children.push(obj)
    }
    if (item.msgtype === "weathercard") {
        variableList = wearthVariable;
    } else if (item.msgtype === "datacard") {
        variableList = datacardVariable;
    }
    else {
        variableList = currency;
    }
    return variableList
}
export {handleVariables}