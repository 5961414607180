<template>
  <div class="dialogue">
    <div class="business-card-template-preview-content">
      <div class="chatbot-box">
        <div class="chatbot-title gray">
          <div class="chatbot-title-text">对话窗</div>
        </div>
        <div class="chatbot-content">
          <div class="message-box" v-if="welcomeConfig.text.content!==''">
            <div class="chatbot-message">
              <p v-html="welcomeConfig.text.content"></p>
            </div>
          </div>
          <div class="message-box" v-for="(item,index) in welcomeConfig.attachments">
            <div class="chatbot-message">
              <div v-if="item.msgtype==='link'">
                <div>
                  <el-image :src="item.picurl" fit="cover" v-if="item.picurl"></el-image>
                  <div
                      style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                      v-if="!item.picurl"
                  >暂无封面
                  </div>
                </div>
                <div>
                  <p style="font-weight: 600">{{ item.title }}</p>
                </div>
                <div>
                  <p>{{ item.desc }}</p>
                </div>
              </div>
              <el-image v-if="item.msgtype==='image'"
                        :src="item.url" fit="cover"></el-image>
              <div v-if="item.msgtype==='video'">
                <img src="https://guoranbot-static.oss-cn-beijing.aliyuncs.com/image/35657870.jpg"
                     style="width: 250px;">
                <div style="font-weight: 600;word-break: break-all">{{ item.name }}</div>
              </div>
              <div v-if="item.msgtype==='miniprogram'">
                <div class="mini-program-img">
                  <el-image fit="cover"
                            :src="item.picUrl"
                            v-if="item.picUrl!==''"
                  ></el-image>
                  <div
                      style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                      v-if="item.picUrl===''"
                  >暂无封面
                  </div>
                </div>
                <div class="mini-program-title" style="font-weight: 600">{{ item.title }}</div>
                <div class="mini-program-title">{{ item.title }}</div>
              </div>
              <div v-if="item.msgtype == 'botcard'">

                <div class="mini-program-img">
                  <el-image
                      fit="cover"
                      :src="item.picurl"
                      v-if="item.picurl!==''"
                  ></el-image>
                  <div
                      style="
                                                background-color: #F5F7FA;
                                                color: #C0C4CC;
                                                height: 90px;
                                                width: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;"
                      v-if="item.picurl===''"
                  >暂无封面
                  </div>
                </div>
                <div class="mini-program-title" style="font-weight: 600">{{ item.title }}</div>
                <div class="mini-program-title">{{ item.desc }}</div>
              </div>
              <div v-if="item.msgtype==='file'">
                <div style="font-weight: 600;word-break: break-all">{{ item.name }}</div>
                <span style="word-break: break-all">{{ item.url }}</span>

              </div>
            </div>
          </div>
        </div>

      </div>
      <span style="font-size:18px;text-align:center;margin-left:100px">{{ preview }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "dialogue",
  data() {
    return {}
  },
  props: ['welcomeConfig', 'preview']
}
</script>

<style scoped lang="less">
.dialogue {
  .business-card-template-preview-content {
    margin: 0px 20px 0px 20px;
    width: 316px;
    height: 550px;
    background: url('./../../../assets/images/phone.png') no-repeat;
    background-size: 316px 550px;
    transform: scale(0.75, 0.75);
    margin-top: -80px;

    .card-box {
      background-color: #2C3138;
      border-radius: 10px;
      box-shadow: 0 0 10px #999999;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      margin: 10px 10px 0px 10px;
      transform: scale(0.75, 0.75);
      position: relative;
      top: 30px;
      height: 30%;

      .user-base-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #F0D7B1;

        .user-base-info-text {
          display: flex;
          flex-direction: column;

          .name {
            font-size: 24px;
            margin-bottom: 10px;
          }
        }

        .user-base-info-img {
          width: 30%;

          image {
            width: 50px;
            height: 50px;
          }
        }
      }

      .user-more-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;

        .user-more-info-item {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          color: #aeb7bd;
          padding: 4px 0px;
          font-size: 9px;

          i {
            margin-right: 10px;
          }
        }
      }
    }

    .edit-box {
      position: fixed;
      top: 160px;
      right: 0px;
      background-color: #FFFFFF;
      padding: 10px;
      border-radius: 20px 0px 0px 20px;
      display: none;
      flex-direction: row;
      align-items: center;

      i {
        margin-right: 5px;
      }
    }

    .card-operation-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0px 0px 10px 0px;
      padding: 10px;
      background-color: #FFFFFF;
      height: 5%;
      transform: scale(0.75, 0.75);

      .card-operation-item {
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        align-items: center;

        image {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-left: -5px;
          z-index: 2;
        }

        .count {
          background-color: #E9E9EB;
          height: 25px;
          line-height: 25px;
          padding: 0px 10px;
          border-radius: 0px 10px 10px 0px;
          margin-left: -5px;
          z-index: 1;
          color: #B2B2B2;
        }
      }

      .share {
        font-size: 14px;
        color: #0F6EFF;

        button {
          border: #FFFFFF;
          background-color: #0F6EFF;
          color: #FFFFFF;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          height: 25px;
          border-radius: none;

          i {
            font-size: 20px;
            margin-right: 5px;
          }
        }

      }
    }

    .chatbot-box {
      display: flex;
      flex-direction: column;
      z-index: 999;
      //background-color: #FFFFFF;
      padding: 5px 20px 20px 20px;
      transform: scale(0.75, 0.75);
      height: 100%;
      position: relative;
      top: -20px;

      .chatbot-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 0px 10px 0px;
        border-bottom: 1px solid #BCBEC2;
      }

      .chatbot-content {
        margin: 10px 0px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .message-box {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin: 10px 0px;

          .chatbot-message {
            background-color: #FFFFFF;
            padding: 10px;
            border: 1px solid #E9E9EB;
            border-radius: 0px 20px 20px 20px;
            max-width: 280px;

            video {
              width: 240px;
            }

            .el-image {
              height: 120px;
              width: 100%;
            }
          }
        }

        .message-box-user {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;

          .user-message {
            background-color: #0F6EFF;
            color: #FFFFFF;
            padding: 10px;
            border: 1px solid #E9E9EB;
            border-radius: 20px 0px 20px 20px;
            max-width: 70vw;
          }
        }
      }
    }
  }
}
</style>