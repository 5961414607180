<template>

  <div class="departmentTreeNode">

    <DepartmentTreeNode v-for="(node,index) in data"
                        :node="node"
                        @onNodeClick="onNodeClick"
                        :selectedAgentInfo="selectedAgentInfo"
                        :sendingRule="sendingRule"
    >
      {{node}}
    </DepartmentTreeNode>
  </div>
</template>
<script>

import DepartmentTreeNode from "./DepartmentTreeNode";

export default {
  name: 'DepartmentTree',
  components: {DepartmentTreeNode},
  data() {
    return {}
  },
  props: {
    data: Array,
    selectedAgentInfo:Object,
    sendingRule:String
  },
  methods:{
    onNodeClick(node){
      this.$emit('onNodeClick',node);
    }
  }
}
</script>