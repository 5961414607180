<template>

  <div class="departmentTree">
    <div class="tree-node">
      <div style="display:inline-block;" class="node-left">

        <div class="node-icon">


          <div style="display: flex;justify-content: flex-start;align-items: center">

            <i :class="displayChildren?'el-icon-arrow-down':'el-icon-arrow-right'" v-if="node.children!=null && node.children.length>0"
               @click="displayChildren=!displayChildren"></i>

            <span v-if="!node.isMember">
            <i class="arsenal_icon arsenalsitemap1"
               style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>
          </span>

            <span v-if="node.isMember">
             <i class="arsenal_icon arsenaluser1"
                style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>
          </span>
          </div>
        </div>

      </div>

      <div style="display:inline-block;" class="node-content" @click="onClick">
        <span v-if="selectedAgentInfo.bindType===0&&selectedAgentInfo.wechatType===0">
          <span v-if="node.isMember">
            <ww-open-data type="userName" :openid="node.name"></ww-open-data>
          </span>
           <span v-else>
            <ww-open-data type="departmentName" :openid="node.name"></ww-open-data>
          </span>
        </span>
        <span v-else> {{ node.name }}</span>
      </div>

      <div style="display:inline-block;" class="node-right">

        <i class="el-icon-check" v-if="node.checked"></i>
      </div>
    </div>

    <div>

      <DepartmentTreeNode v-for="(childrenNode,index) in node.children" :node="childrenNode"
                          v-if="displayChildren"
                          class="tree-children-node"
                          @onNodeClick="onNodeClick"
                          :selectedAgentInfo="selectedAgentInfo"
                          :sendingRule="sendingRule"
      >
      </DepartmentTreeNode>
    </div>

    {{ node.label }}
  </div>

</template>
<script>

export default {
  name: 'DepartmentTreeNode',
  data() {
    return {
      displayChildren: false,
    }
  },
  props: {
    node: {
      default() {
        return {};
      }
    },
    selectedAgentInfo:{
      default() {
        return {};
      }
    },
    sendingRule:String
  },
  methods: {
    onClick() {
      if ((this.sendingRule==='NEW_EMPLOYEE'&&!this.node.isMember)||this.sendingRule!=='NEW_EMPLOYEE'){
        this.node.checked = !this.node.checked;
        this.$emit('onNodeClick', this.node);
      }
    },
    onNodeClick(node){
      this.$emit('onNodeClick', node);
    },
  },
    mounted() {
      this.$nextTick(()=>{
        if (this.selectedAgentInfo.bindType===0&&this.selectedAgentInfo.wechatType===0){
          WWOpenData.bind(this.$el);
        }
      })
    }
}
</script>
<style lang="less">
  .departmentTree{
    .tree-node{
      cursor: pointer;
    }
    .tree-children-node{
      padding: 8px 0px 0px 30px;
      cursor: pointer;
    }
    .node-content{
      width: 80%;
    }
    .node-right{
      float: right;
    }
    .el-icon-arrow-down{
      padding: 4px;
      font-weight: 600;
    }
    .el-icon-arrow-right{
      padding: 4px;
      font-weight: 600;
    }
    .el-icon-check{
      font-weight: 600;
    }
  }

</style>