<template>
    <div id="sendContentComponent">
        <div class="component-list">
            <div class="component-item" v-for="(item, index) in componentList" :key="index" @click="componentCheck(item)">
                <img src="../../../assets/images/sucaiku.png" v-if="item.type === 'material'" />
                <img src="../../../assets/images/wenben.png" v-else-if="item.type === 'text'">
                <img src="../../../assets/images/tupian.png" v-else-if="item.type === 'image'">
                <img src="../../../assets/images/shipin.png" v-else-if="item.type === 'video'">
                <img src="../../../assets/images/tuwen.png" v-else-if="item.type === 'link'">
                <img src="../../../assets/images/wenjian.png" v-else-if="item.type === 'file'">
                <img src="../../../assets/images/xiaoxicard.png" v-else-if="item.type === 'messagecard'">
                <img src="../../../assets/images/jiqiren.png" v-else-if="item.type === 'botcard'">
                <img src="../../../assets/images/tianqi.png" v-else-if="item.type === 'weathercard'">
                <img src="../../../assets/images/questionnaire.png" width="36px" height="36px" v-else-if="item.type === 'questionnaire'">
                <img src="../../../assets/images/shuju.png" v-else>
                <span class="component-name">{{ $t('taskContent.sendContentCom.' + item.type) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "sendContentComponent",
    data () {
        return {
            componentList: [
                {
                    name: "从素材中心添加",
                    type: "material",
                },
                {
                    name: "文本",
                    type: "text",
                },
                {
                    name: "图片",
                    type: "image",
                },
                {
                    name: "视频",
                    type: "video",
                },
                {
                    name: "图文",
                    type: "link",
                },
                {
                    name: "文件",
                    type: "file",
                },
                {
                    name: "消息卡片",
                    type: "messagecard",
                },
                {
                    name: "机器人交互卡片",
                    type: "botcard",
                },
                {
                    name: "天气卡片",
                    type: "weathercard",
                },
                {
                    name: "机器人数据卡片",
                    type: "datacard",
                },
                {
                    name: "问卷调查",
                    type: "questionnaire"
                }
            ],
        };
    },
    methods: {
        componentCheck (item) {
            this.$emit("componentCheck", item);
        },
    },
};
</script>

<style scoped lang="less">
#sendContentComponent {
    .component-list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 30px;

        .component-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90px;
            height: 90px;
            background: #FFFFFF;
            border: 1px solid #D0DAF6;
            border-radius: 5px;
            flex: none;
            margin-bottom: 20px;
            font-size: 12px;
            cursor: pointer;

            .component-name {
                padding-top: 8px;
            }
        }
    }
}
</style>